body {
  margin: 0;
  font-family: "Neue Montreal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Montreal';
  src: url(font/NeueMontreal-Regular.otf);
  font-style: normal;
  font-weight: 100;

}
@font-face {
  font-family: 'Montreal';
  src: url(font/NeueMontreal-RegularItalic.otf);
  font-style: italic;
  font-weight: 100;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}

.trails-text {
  position: relative;
  width: 100%;
  height: 70px;
  line-height:70px;
  color: black;
  font-size: 4em;
  font-weight: 100;
  text-transform: uppercase;
  will-change: transform, opacity;
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
  }

.trails-text > div {
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  display: block;
  margin: auto;
  width: 400px;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.link {
  text-decoration: none;
  color: black;
  text-align: right;
}
.link:hover{
  font-style: italic;
}
.imgcont{
  width: 80%;
}
.pegaso {
  width: 100%;
  height: auto;

}

.titulos {
  font-size: 55px;
  font-family: 'Montreal';
  font-weight: 100;
  letter-spacing: 4px;
  line-height: 1;
}
.tituloin{
  font-size: 30px;
  font-family: 'Montreal';
  font-style: italic;
  font-weight: 100;
  text-align: right;

}
.textos {
  font-size: 20px;
  font-family: 'Montreal';
  font-weight: 100;
  text-align: right;
}
.container{
  text-align: right;
  
}
.flex-container{
  display: flex;
  flex-direction: row;
  margin: 0;
  
}

.pegasocont{
height: 100%;
}

.boton{
  padding-right: 100%;
}

.contdig {
-webkit-writing-mode: vertical-lr;
-ms-writing-mode: tb-lr;
writing-mode: vertical-lr;
transform: rotate(180deg);
width: 40%;
padding: 1% 0 0;
}
.cons {
 padding: 0;
 font-size: 20px;
 font-family: 'Montreal';
 font-weight: 100;
 
}
.rev {
  padding-bottom: 2rem;
  width: 100%;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-family: 'Montreal';
  font-weight: 100;
  font-size: 14px;
  background-color: white;
  padding-bottom: 2rem;
  text-align: left;
}

table{
  width: 100%;
  margin: auto;
}
td { 
  width: 33%;
}
.direc {
  float: left;
  padding-left: 2rem;
}
.contac {
  display: block;
  padding-left: 10rem;
}
.inst {
  float: right;
  padding-right: 2rem;
}
a {
  text-decoration: none;
  color: black;
}
a:hover{
  font-style: italic;
}

.tabpre {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.tabtit {
  padding: 10px;
  width: 10%;
}
.tabrot{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.epsonrow {
  width: 5%;
}
.tabitem {
  padding: 20px;
}
.tabhead{
  padding: 20px;
  font-family: 'Montreal';
 font-weight: 100;
}
.row {
  display: flex;
  flex-direction: row;
}
.rowitem {
  border: 1px solid #999;
  width: 15%;
  
}
.rowlat{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  border: 1px solid #999;
}
.epson{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: block;
  margin: auto;
 
}
.asteri {
  font-size: 20px;
  font-family: 'Montreal';
  font-weight: 100;
}
/* Smartphones (portrait and landscape) – ------- – */
@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
  
  .logo {
    display: block;
    margin: auto;
    width: 200px;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .titulos {
    font-size: 22px;
    font-family: 'Montreal';
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 1;
  }
  .tituloin{
    font-size: 20px;
    font-family: 'Montreal';
    font-style: italic;
    font-weight: 100;
    text-align: right;
  
  }
  .textos {
    font-size: 12px;
    font-family: 'Montreal';
    font-weight: 100;
    text-align: right;
  }
  .footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: 'Montreal';
    font-weight: 100;
    font-size: 12px;
    background-color: white;
    padding-bottom: 2rem;

  }
  .footerpega {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: 'Montreal';
    font-weight: 100;
    font-size: 6px;
    background-color: white;
    padding-bottom: 2rem;
    

  }
  .pegasocont{
    padding-top: 5rem;
    padding-left: 3rem;
    position: absolute;
  }
  .contac {
    display: block;
    padding-left: 1rem;
  }
  .tabitem {
    padding: 10px;
    font-size: 10px;
  }
  .tabtit {
    padding: 10px;
    width: 10%;
    font-size: 10px;
  }
  .asteri {
    font-size: 12px;
    font-family: 'Montreal';
    font-weight: 100;
  }
  .epson{
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: block;
    margin: auto;
    font-size: 10px;
  }
  .tabhead{
    font-size: 10px;
  }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      
  .logo {
    display: block;
    margin: auto;
    width: 300px;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .titulos {
    font-size: 45px;
    font-family: 'Montreal';
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 1;
  }
  .tituloin{
    font-size: 30px;
    font-family: 'Montreal';
    font-style: italic;
    font-weight: 100;
    text-align: right;
  
  }
  .textos {
    font-size: 18px;
    font-family: 'Montreal';
    font-weight: 100;
    text-align: right;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: 'Montreal';
    font-weight: 100;
    font-size: 12px;
    background-color: white;
    padding-bottom: 2rem;

  }
  .footerpega {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: 'Montreal';
    font-weight: 100;
    font-size: 12px;
    background-color: white;
    padding-bottom: 2rem;
    

  }
  .pegasocont{
    padding-top: 5rem;
    padding-left: 3rem;
  }
  .contac {
    display: block;
    padding-left: 2rem;
  }
  .tabitem {
    padding: 10px;
    font-size: 14px;
  }
  .tabtit {
    padding: 10px;
    width: 10%;
    font-size: 14px;
  }
  .epson{
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: block;
    margin: auto;
    font-size: 14px;
  }
  .tabhead{
    font-size: 14px;
  }
    }
    @media only screen and (min-device-width : 1024px) and (max-device-width : 1440px) {
      
      .logo {
        display: block;
        margin: auto;
        width: 350px;
        padding-top: 1rem;
        padding-bottom: 2rem;
      }
      .titulos {
        font-size: 50px;
        font-family: 'Montreal';
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
      }
      .tituloin{
        font-size: 30px;
        font-family: 'Montreal';
        font-style: italic;
        font-weight: 100;
        text-align: right;
      
      }
      .textos {
        font-size: 18px;
        font-family: 'Montreal';
        font-weight: 100;
        text-align: right;
      }
      .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        font-family: 'Montreal';
        font-weight: 100;
        font-size: 10px;
        background-color: white;
        padding-bottom: 2rem;
    
      }
      
      .pegasocont{
        padding-top: 2rem;
        padding-left: 3rem;
      }
      .contac {
        display: block;
        padding-left: 2rem;
      }
        }